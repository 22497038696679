import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'
import { useAnalytics } from '@/hooks/useAnalytics'
import { api } from '@/lib/api'
import { AppRoutes } from '@/routes'
import { LoaderCircleIcon } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export function HomePage() {
  const navigate = useNavigate()
  const analytics = useAnalytics()

  const mutation = api.archives.scrape.useMutation({
    onSuccess: (url) => {
      navigate(AppRoutes.buildArchiveRoute(url))
    },
    onError: (error) => {
      toast({
        title: 'Error Archiving',
        description: error.message,
        variant: 'destructive',
      })
    },
  })

  const [url, setUrl] = useState('')

  return (
    <>
      <section className="bg-background text-foreground flex w-full flex-1 items-center justify-center sm:pb-20">
        <div className="flex w-full max-w-screen-sm flex-col items-center justify-center text-center">
          <h1 className="lg:leading-tighter text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
            Archive the Web on Bitcoin
          </h1>
          <p className="text-foreground mt-2 max-w-2xl md:text-xl">
            Easily save and access websites with our secure,
            <br />
            decentralized archiving platform. Powered by Bitcoin.
          </p>
          <div className="mt-6 flex w-full gap-4">
            <form
              className="flex flex-1 items-center justify-between overflow-hidden rounded-md border border-indigo-500"
              onSubmit={(e) => {
                analytics.sendEvent('page_scraped', { url })

                e.preventDefault()

                mutation.mutate({ url })
              }}
            >
              <input
                type="url"
                placeholder="Enter a URL"
                className="flex-1 rounded-md p-2 focus-visible:rounded-r-none"
                onChange={(e) => setUrl(e.target.value)}
                autoFocus
                value={url}
              />
              <Button
                type="submit"
                variant="theme"
                className="rounded-none px-5 text-base disabled:opacity-100"
                disabled={mutation.isPending || !url}
              >
                {mutation.isPending ? 'Archiving...' : 'Archive'}
                {mutation.isPending && (
                  <LoaderCircleIcon className="animate-spin" />
                )}
              </Button>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}
