import { Routes as ReactRouterRoutes, Route } from 'react-router-dom'
import { Layout } from './Layout'
import { HomePage } from './pages/HomePage'
import { ArchivePage } from './pages/Archive/ArchivePage'
import { BrowsePage } from './pages/Browse/BrowsePage'
import { AdminPage } from './pages/AdminPage'
import { AboutPage } from './pages/AboutPage'
import { StatsPage } from './pages/StatsPage'

const ROUTES = {
  HOME: '/',
  ARCHIVE: '/archive',
  BROWSE: '/browse',
  ADMIN: '/admin/:adminSecret',
  ABOUT: '/about',
  CONTACT: '/contact',
  STATS: '/stats',
}

// eslint-disable-next-line react-refresh/only-export-components
export const AppRoutes = {
  ...ROUTES,
  buildArchiveRoute: (url: string) => `${ROUTES.ARCHIVE}?url=${url}`,
}

export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route element={<Layout />}>
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.ARCHIVE} element={<ArchivePage />} />
        <Route path={ROUTES.BROWSE} element={<BrowsePage />} />
        <Route path={ROUTES.ADMIN} element={<AdminPage />} />
        <Route path={ROUTES.ABOUT} element={<AboutPage />} />
        <Route path={ROUTES.STATS} element={<StatsPage />} />
      </Route>
    </ReactRouterRoutes>
  )
}
