import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { api } from '@/lib/api'
import { AppRoutes } from '@/routes'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { InfoIcon, LoaderCircleIcon, RefreshCcw } from 'lucide-react'
import { useInView } from 'react-intersection-observer'
import { Button } from '@/components/ui/button'
import { IArchive } from 'backend/src/interface'
import { toast } from '@/components/ui/use-toast'
import { formatCurrency } from '@/lib/formatting'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Helmet } from 'react-helmet'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'

export function AdminPage() {
  const { adminSecret } = useParams<{ adminSecret: string }>()

  const {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = api.archives.approvalRequired.useInfiniteQuery(
    { secret: adminSecret ?? '' },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      enabled: !!adminSecret,
    },
  )

  const { data: walletBalance } = api.ordinals.walletBalance.useQuery()
  const { data: btcInfo } = api.ordinals.btcFees.useQuery()

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView && hasNextPage) {
        fetchNextPage()
      }
    },
  })

  if (isLoading) {
    return (
      <div className="flex justify-center pt-8 lg:pt-14">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    )
  }

  if (!adminSecret) return <div>Unauthorized.</div>

  return (
    <div className="pt-8">
      <div className="flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
        <h1 className="mb-1 text-3xl font-bold">Admin</h1>
        <div className="flex items-center gap-2">
          <div>
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="outline" className="flex items-center gap-1.5">
                  BTC Info <InfoIcon className="h-4 w-4" />
                </Button>
              </DialogTrigger>
              <DialogContent>
                <pre>{JSON.stringify(btcInfo, null, 2)}</pre>
              </DialogContent>
            </Dialog>
          </div>
          <div className="text-lg">
            Wallet Balance: {formatCurrency(walletBalance?.total_usd ?? 0)}
          </div>
        </div>
      </div>
      <section className="mt-4 grid grid-cols-1 gap-x-12 gap-y-10 sm:grid-cols-2">
        {data?.pages.map((group, i) => (
          <React.Fragment key={i}>
            {group.archives.map((a) => (
              <ArchiveCard
                archive={a}
                adminSecret={adminSecret}
                walletBalance={walletBalance?.total_usd}
                key={a.url}
                refetch={refetch}
              />
            ))}
          </React.Fragment>
        ))}
        {hasNextPage && <div ref={ref} />}
        {isFetchingNextPage && (
          <div className="mt-6 flex justify-center">
            <LoaderCircleIcon className="animate-spin" />
          </div>
        )}
      </section>
    </div>
  )
}

function ArchiveCard({
  archive,
  adminSecret,
  walletBalance,
  refetch,
}: {
  archive: IArchive
  adminSecret: string
  walletBalance: number | undefined
  refetch: () => void
}) {
  const [confirmRescrapeOpen, setConfirmRescrapeOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmPremiumOpen, setConfirmPremiumOpen] = useState(false)

  const mutation = api.archives.approve.useMutation({
    onSuccess: async () => {
      toast({ title: 'Approved!' })

      refetch()

      setConfirmOpen(false)
      setConfirmPremiumOpen(false)
    },
    onError: (error) => {
      toast({
        title: 'Error Approving',
        description: error.message,
        variant: 'destructive',
        duration: Infinity,
      })
    },
  })

  const scrapeMutation = api.archives.scrape.useMutation({
    onSuccess: async () => {
      toast({ title: 'Rescraped!' })

      refetch()

      setConfirmRescrapeOpen(false)
    },
    onError: (error) => {
      toast({
        title: 'Error Rescraping',
        description: error.message,
        variant: 'destructive',
        duration: Infinity,
      })
    },
  })

  const { data } = api.ordinals.estimateFeesUsd.useQuery({
    url: archive.url,
  })

  const standardFees = data?.standardFees
  const premiumFees = data?.premiumFees

  const disableButtons =
    mutation.isPending || scrapeMutation.isPending || !walletBalance
  const disableStandard =
    walletBalance && standardFees
      ? walletBalance < standardFees
      : false || standardFees === undefined
  const disablePremium =
    walletBalance && premiumFees
      ? walletBalance < premiumFees
      : false || premiumFees === undefined

  return (
    <Card key={archive.url}>
      <Helmet title="Admin | Ark" />
      <CardHeader>
        <CardTitle className="line-clamp-2 leading-tight">
          <Link
            to={AppRoutes.buildArchiveRoute(archive.url)}
            target="_blank"
            className="hover:underline"
          >
            {archive.title || new URL(archive.url).hostname}
          </Link>
          {/* Pad with extra lines so all cards are even */}
          <br />
          <br />
        </CardTitle>
        <CardDescription className="line-clamp-4">
          {archive.excerpt ?? archive.url}

          {/* Pad with extra lines so all cards are even */}
          <br />
          <br />
          <br />
          <br />
        </CardDescription>
      </CardHeader>

      <CardFooter className="flex flex-col items-start gap-3">
        <p>{new Intl.DateTimeFormat('en-us').format(archive.date)}</p>

        <AlertDialog
          open={confirmRescrapeOpen}
          onOpenChange={setConfirmRescrapeOpen}
        >
          <AlertDialogTrigger asChild>
            <Button
              variant="outline"
              size="sm"
              className="w-full gap-1.5"
              disabled={disableButtons}
            >
              {scrapeMutation.isPending ? (
                <>
                  Rescraping...
                  <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                </>
              ) : (
                <>
                  Rescrape <RefreshCcw className="h-4 w-4" />
                </>
              )}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Are you sure you want to rescrape this archive?
              </AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={() => setConfirmRescrapeOpen(false)}>
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction
                onClick={async () =>
                  await scrapeMutation.mutateAsync({
                    url: archive.url,
                    override: true,
                  })
                }
              >
                {scrapeMutation.isPending ? (
                  <>
                    Rescraping...{' '}
                    <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                  </>
                ) : (
                  'Rescrape'
                )}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        {/* Standard */}
        <AlertDialog open={confirmOpen} onOpenChange={setConfirmOpen}>
          <AlertDialogTrigger asChild>
            <Button
              variant="outline"
              size="sm"
              className="w-full gap-1.5"
              disabled={disableButtons || disableStandard}
            >
              {mutation.isPending ? (
                <>
                  Approving...{' '}
                  <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                </>
              ) : (
                <>
                  Approve for{' '}
                  {typeof standardFees === 'undefined' ? (
                    <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                  ) : (
                    formatCurrency(standardFees)
                  )}{' '}
                  (hash only)
                </>
              )}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Are you sure you want to approve this archive?
              </AlertDialogTitle>
              <AlertDialogDescription>
                It will cost{' '}
                <b className="text-black">
                  {typeof standardFees === 'undefined'
                    ? '...'
                    : formatCurrency(standardFees)}
                </b>{' '}
                to approve this archive.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={() => setConfirmOpen(false)}>
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction
                onClick={() =>
                  mutation.mutate({
                    url: archive.url,
                    secret: adminSecret,
                    type: 'standard',
                  })
                }
              >
                {mutation.isPending ? (
                  <>
                    Approving...{' '}
                    <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                  </>
                ) : (
                  'Approve'
                )}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        {/* Premium */}
        <AlertDialog
          open={confirmPremiumOpen}
          onOpenChange={setConfirmPremiumOpen}
        >
          <AlertDialogTrigger asChild>
            <Button
              size="sm"
              className="w-full gap-1.5"
              disabled={disableButtons || disablePremium}
            >
              {mutation.isPending ? (
                <>
                  Approving...{' '}
                  <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                </>
              ) : (
                <>
                  Approve for{' '}
                  {typeof premiumFees === 'undefined' ? (
                    <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                  ) : (
                    formatCurrency(premiumFees)
                  )}{' '}
                  (fulltext)
                </>
              )}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Are you sure you want to approve this archive?
              </AlertDialogTitle>
              <AlertDialogDescription>
                It will cost{' '}
                <b className="text-black">
                  {typeof premiumFees === 'undefined'
                    ? '...'
                    : formatCurrency(premiumFees)}
                </b>{' '}
                to approve this archive.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={() => setConfirmPremiumOpen(false)}>
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction
                onClick={() =>
                  mutation.mutate({
                    url: archive.url,
                    secret: adminSecret,
                    type: 'premium',
                  })
                }
              >
                {mutation.isPending ? (
                  <>
                    Approving...{' '}
                    <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                  </>
                ) : (
                  'Approve'
                )}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </CardFooter>
    </Card>
  )
}
