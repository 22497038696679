import { AppRoutes } from '@/routes'
import { Link } from 'react-router-dom'
import { Card, CardHeader, CardContent, CardTitle, CardFooter } from './ui/card'
import { IArchive } from '@/types'
import { Badge } from './ui/badge'

interface Props {
  archive: IArchive
}

export function ArchiveCard(props: Props) {
  const { archive } = props

  return (
    <Link to={AppRoutes.buildArchiveRoute(archive.url)} key={archive._id}>
      <Card className="relative transition-all hover:drop-shadow-xl">
        {/* {archive.txid && (
          <div className="absolute right-0 top-0 m-2 h-4 w-4 rounded-full bg-green-500" />
        )} */}
        <CardHeader>
          {!!archive.txid && (
            <div className="flex items-center gap-2">
              <Badge variant="theme">Archived</Badge>
              <Badge variant={archive.fullText ? 'default' : 'secondary'}>
                {archive.fullText ? 'Full Text' : 'Hash Only'}
              </Badge>
            </div>
          )}
          <CardTitle className="line-clamp-2 leading-tight">
            {archive.title || new URL(archive.url).hostname}
            <br />
            <br />
          </CardTitle>
        </CardHeader>
        <CardContent className="line-clamp-2 overflow-hidden break-words">
          {archive.excerpt ?? archive.url}

          {/* Pad with extra lines so all cards are even */}
          <br />
          <br />
          <br />
        </CardContent>
        <CardFooter className="mt-4 text-sm font-bold">
          <p>{new Intl.DateTimeFormat('en-us').format(archive.date)}</p>
        </CardFooter>
      </Card>
    </Link>
  )
}
