export function mhtmlToHtml(mhtmlContent: string) {
  // Split the MHTML content into parts by boundary
  const boundaryRegex = /boundary="([^"]+)"/
  const boundaryMatch = mhtmlContent.match(boundaryRegex)
  if (!boundaryMatch) {
    console.error('Could not find the boundary in the MHTML file.')
    return
  }
  const boundary = boundaryMatch[1]
  const parts = mhtmlContent.split(`--${boundary}`)

  // Find and extract the HTML part
  let htmlContent = ''
  parts.forEach((part) => {
    if (part.includes('Content-Type: text/html')) {
      const htmlStart = part.indexOf('<html')
      if (htmlStart !== -1) {
        htmlContent = part.substring(htmlStart).trim()
      }
    }
  })

  if (htmlContent) {
    return htmlContent
  } else {
    console.log('No HTML content found in the MHTML file.')
  }
}

export const formatDate = (date: number) =>
  new Intl.DateTimeFormat('en-US').format(date)

export const formatCurrency = (amount: number) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    amount,
  )

export const formatFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
