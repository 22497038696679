import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
  SelectSeparator,
} from '@/components/ui/select'
import { api } from '@/lib/api'
import { ListFilterIcon } from 'lucide-react'

interface Props {
  site: string
  setSite: (site: string) => void
}

export function SiteFilter(props: Props) {
  const { site, setSite } = props

  const { data: domains } = api.archives.domains.useQuery()

  const _setSite = (site: string) => {
    if (site === 'all') {
      setSite('')
    } else {
      setSite(site)
    }
  }

  return (
    <Select value={site} onValueChange={_setSite}>
      <SelectTrigger className="w-min max-w-52 gap-2">
        <ListFilterIcon className="mr-1.5 h-4 w-4 flex-shrink-0" />
        <div className="truncate">
          <SelectValue placeholder="Site" />
        </div>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="all">All</SelectItem>
          <SelectItem value="x.com">X/Twitter</SelectItem>
          <SelectItem value="gutenberg.org">Project Gutenberg</SelectItem>
        </SelectGroup>
        <SelectSeparator />
        <SelectGroup>
          <SelectLabel>All</SelectLabel>
          {domains
            ?.filter((d) => d !== 'x.com' && d !== 'gutenberg.org')
            .map((domain) => (
              <SelectItem key={domain} value={domain}>
                {domain}
              </SelectItem>
            ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
