import { api } from '@/lib/api'
import React, { useState } from 'react'
import { CircleXIcon, LoaderCircleIcon } from 'lucide-react'
import { useInView } from 'react-intersection-observer'
import { Helmet } from 'react-helmet'
import { useSearchParams } from 'react-router-dom'
import { ArchiveCard } from '@/components/ArchiveCard'
import { ArchiveStatus, BrowseSort } from '@/types'
import { StatusFilter } from './components/StatusFilter'
import { SiteFilter } from './components/DomainFilter'
import { Badge } from '@/components/ui/badge'
import { Sorting } from './components/Sorting'

const PARAMS = { SITE: 'site', SORT: 'sort', STATUS: 'status' }

const STATUS_TEXT: Record<string, string> = {
  archived: 'Archived only',
  unsaved: 'Unsaved only',
}

const SITE_TEXT: Record<string, string> = {
  'x.com': 'X/Twitter',
  'gutenberg.org': 'Project Gutenberg',
}

export function BrowsePage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const site = searchParams.get(PARAMS.SITE) || ''
  const sort: BrowseSort =
    (searchParams.get(PARAMS.SORT) as BrowseSort) || 'desc'
  const status: ArchiveStatus =
    (searchParams.get(PARAMS.STATUS) as ArchiveStatus) || ''
  const [innerStatus, setInnerStatus] = useState<ArchiveStatus | undefined>(
    status,
  )

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isPending } =
    api.archives.browse.useInfiniteQuery(
      {
        status: status ? status : 'all',
        domain: site || undefined,
        sort,
      },
      { getNextPageParam: (lastPage) => lastPage.nextPage },
    )

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView && hasNextPage) {
        fetchNextPage()
      }
    },
  })

  const setSite = (newDomain: string) => {
    setSearchParams((prev) => {
      if (newDomain) {
        prev.set(PARAMS.SITE, newDomain)
      } else {
        prev.delete(PARAMS.SITE)
      }
      return prev
    })
  }

  const setStatus = (newStatus: ArchiveStatus) => {
    setInnerStatus(newStatus)

    setSearchParams((prev) => {
      // Since 'all' is the default value, we don't need to store it in a query param
      if (newStatus && newStatus !== 'all') {
        prev.set(PARAMS.STATUS, newStatus)
      } else {
        prev.delete(PARAMS.STATUS)
      }
      return prev
    })
  }

  const setSort = (newSort: BrowseSort) => {
    setSearchParams((prev) => {
      // Since 'desc' is the default value, we don't need to store it in a query param
      if (newSort && newSort !== 'desc') {
        prev.set(PARAMS.SORT, newSort)
      } else {
        prev.delete(PARAMS.SORT)
      }
      return prev
    })
  }

  const isEmpty =
    !isPending && (!data?.pages.length || !data?.pages[0].archives.length)

  const statusText = status ? STATUS_TEXT[status] : undefined
  return (
    <div className="mx-auto w-full max-w-screen-xl pt-8">
      <Helmet title="Browse | Ark" />
      <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
        <h1 className="mb-1 text-3xl font-bold">Browse</h1>
        <div className="flex items-center gap-2">
          <SiteFilter site={site} setSite={setSite} />
          <StatusFilter status={innerStatus} setStatus={setStatus} />
          <Sorting sort={sort} setSort={setSort} />
        </div>
      </div>
      <section className="mt-10 grid grid-cols-1 gap-y-10 sm:mt-4 sm:grid-cols-2 sm:gap-x-12">
        {statusText || site ? (
          <div className="flex flex-col flex-wrap items-center justify-center gap-2 sm:col-span-2 sm:flex-row sm:justify-start">
            {statusText || site ? <div className="">Filters:</div> : null}
            {statusText && (
              <Badge size="lg" variant="secondary">
                {statusText}
                <button onClick={() => setStatus('all')}>
                  <CircleXIcon className="ml-2 h-5 w-5" />
                </button>
              </Badge>
            )}
            {site && (
              <Badge variant="secondary" size={'lg'}>
                {SITE_TEXT[site] ?? site}
                <button onClick={() => setSite('')}>
                  <CircleXIcon className="ml-2 h-5 w-5" />
                </button>
              </Badge>
            )}
          </div>
        ) : null}
        {isPending && (
          <div className="mt-6 flex justify-center sm:col-span-2">
            <LoaderCircleIcon className="animate-spin" />
          </div>
        )}
        {isEmpty && (
          <div className="mt-6 text-center sm:text-left">
            No archives found.
          </div>
        )}
        {data?.pages.map((group, i) => (
          <React.Fragment key={i}>
            {group.archives.map((a) => (
              <ArchiveCard archive={a} key={a._id} />
            ))}
          </React.Fragment>
        ))}
        {hasNextPage && <div ref={ref} />}
      </section>
      {isFetchingNextPage && (
        <div className="mt-6 flex w-full justify-center">
          <LoaderCircleIcon className="animate-spin" />
        </div>
      )}
    </div>
  )
}
