import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select'
import { BrowseSort } from '@/types'
import { SortAscIcon, SortDescIcon } from 'lucide-react'

interface Props {
  sort: BrowseSort
  setSort: (sort: BrowseSort) => void
}

const SORT_OPTIONS: Record<NonNullable<BrowseSort>, string> = {
  desc: 'Newest',
  asc: 'Oldest',
}

export function Sorting(props: Props) {
  const { setSort } = props

  const sort = props.sort ?? 'desc'

  return (
    <Select
      value={sort}
      onValueChange={(value) => setSort(value as BrowseSort)}
    >
      <SelectTrigger className="w-[140px]">
        {sort === 'asc' ? (
          <SortAscIcon className="h-4 w-4" />
        ) : (
          <SortDescIcon className="h-4 w-4" />
        )}
        {SORT_OPTIONS[sort]}
      </SelectTrigger>
      <SelectContent>
        {Object.entries(SORT_OPTIONS).map(([key, value]) => (
          <SelectItem key={key} value={key}>
            {value}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
