import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { toast } from '@/components/ui/use-toast'
import { api } from '@/lib/api'
import { formatFileSize, mhtmlToHtml } from '@/lib/formatting'
import {
  CopyIcon,
  DownloadIcon,
  LinkIcon,
  LoaderCircleIcon,
} from 'lucide-react'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { EventChannels } from 'backend/src/lib/event-channels'
import { ArchiveHeader } from './components/ArchiveHeader'
import { useAnalytics } from '@/hooks/useAnalytics'

export function ArchivePage() {
  const [params] = useSearchParams()
  const url = params.get('url') || ''

  const [payModal, setPayModal] = useState(false)

  const analytics = useAnalytics()

  const {
    data: archive,
    isLoading: loadingArchive,
    refetch: refetchArchive,
  } = api.archives.view.useQuery({ url })

  const { addEventListener } = useSse(url)

  useEffect(() => {
    addEventListener('paymentReceived', async () => {
      toast({ title: 'Payment Received' })

      analytics.sendEvent('payment_made', { url })

      if (payModal) {
        setPayModal(false)
      }

      await refetchArchive()
    })

    addEventListener('minted', () => {
      toast({ title: 'Minted' })

      refetchArchive()
    })
  }, [addEventListener])

  if (loadingArchive) {
    return (
      <div className="flex justify-center pt-8 lg:pt-14">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    )
  }

  if (!archive) {
    return <div className="flex justify-center pt-8 lg:pt-14">Not found :(</div>
  }

  return (
    <div className="mx-auto w-full max-w-screen-lg pb-8 pt-8 lg:pb-14 lg:pt-14">
      <div className="mb-8">
        <ArchiveHeader
          archive={archive}
          payModal={payModal}
          setPayModal={setPayModal}
          refetchArchive={refetchArchive}
        />
      </div>

      <a
        href={archive.url}
        target="_blank"
        rel="noreferrer noopener"
        className="mb-1 break-words text-3xl font-bold"
      >
        {archive.title ?? archive?.url}
      </a>
      <div className="mb-8 mt-4 flex flex-col gap-2 sm:mt-1 sm:flex-row sm:items-center">
        <div className="flex items-center gap-2">
          <Popover>
            <PopoverTrigger className="flex items-center gap-1 text-sm text-indigo-600">
              <LinkIcon className="h-4 w-4" /> Source
            </PopoverTrigger>
            <PopoverContent className="ml-4 grid w-full max-w-[calc(100vw-2rem)] grid-cols-[1fr,auto] gap-x-3 sm:max-w-max">
              <a
                href={archive.url}
                target="_blank"
                rel="noreferrer noopener"
                className="text-sm text-indigo-600 underline-offset-4 hover:underline"
                style={{ wordBreak: 'break-word' }}
              >
                {archive.url}
              </a>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(archive.url ?? '')
                  toast({ title: 'Copied' })
                }}
              >
                <CopyIcon className="h-5 w-5 sm:h-4 sm:w-4" />
              </button>
            </PopoverContent>
          </Popover>
          <div>|</div>
          <a
            href={`/api/download?url=${archive.url}`}
            className="flex items-center gap-1 text-sm text-indigo-600"
            onClick={() => analytics.sendEvent('archive_downloaded', { url })}
          >
            <DownloadIcon className="h-4 w-4" /> Download
          </a>
        </div>
        <div className="hidden sm:block">|</div>
        <div className="text-sm">
          {formatFileSize(archive.fileSize)} (
          {formatFileSize(archive.fileSizeCompressed)} compressed)
        </div>
      </div>
      <div className="text-muted-foreground text-sm">
        Last updated:{' '}
        {new Date(archive.date).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}
      </div>
      <section className="bg-background text-foreground mt-8 flex w-full whitespace-pre-line">
        {archive.plainText ? (
          <div>{archive.textContent}</div>
        ) : (
          <iframe
            srcDoc={mhtmlToHtml(archive.textContent || '')}
            className="h-56 w-full"
          />
        )}
      </section>
    </div>
  )
}

function useSse(url: string) {
  const [sse, setSse] = useState<EventSource | null>(null)

  useEffect(() => {
    const sse = new EventSource(`/api/sse?url=${url}`)
    setSse(sse)

    return () => {
      sse.close()
      setSse(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addEventListener = <Event extends keyof EventChannels>(
    event: Event,
    cb: (data: EventChannels[Event]) => void,
  ) => {
    sse?.addEventListener(event, ({ data }) => {
      cb(JSON.parse(data))
    })
  }

  return { addEventListener }
}
