import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'

import { IArchive } from '@/types'
import { LoaderCircleIcon } from 'lucide-react'
import { Unsaved } from './Unsaved'
import { SavedDetails } from './SavedDetails'

interface Props {
  archive: IArchive
  payModal: boolean
  setPayModal: (modalOpen: boolean) => void
  refetchArchive: () => void
}

export function ArchiveHeader(props: Props) {
  const { archive } = props

  if (archive.mintingInProgress) {
    return (
      <Alert>
        <LoaderCircleIcon className="h-4 w-4 animate-spin" />
        <AlertTitle>Saving in Progress</AlertTitle>
        <AlertDescription>
          This page is currently being saved on Bitcoin. This could take several
          minutes or hours depending on the current blockchain conditions. You
          can view the final transaction below once it is complete.
        </AlertDescription>
      </Alert>
    )
  }

  return archive.txid ? (
    <SavedDetails archive={archive} />
  ) : (
    <Unsaved {...props} />
  )
}
